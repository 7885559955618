import * as React from 'react';
import {withGlobalProps, ProvidedGlobalProps} from '../../../../providers/globalPropsProvider';
import s from './CloseQuickView.scss';
import {CloseWithBackground} from '../../../../icons/dist/components/CloseWithBackground';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {compose} from '../../../../commons/utils';
import cx from 'classnames';

export const enum CloseQuickViewHooks {
  CTA = 'close-quick-view',
}

export const CloseQuickView = compose(
  withTranslations('globals.texts'),
  withGlobalProps
)(
  ({
    t,
    globals: {
      closeWixModal,
      experiments: {fixQuickviewCloseButton},
    },
  }: ProvidedGlobalProps & IProvidedTranslationProps) => {
    return (
      <button
        type="button"
        data-hook={CloseQuickViewHooks.CTA}
        aria-label={t('productPage.sr.quickView.close.button')}
        className={cx(s.root, {[s.closeButtonStyleFix]: fixQuickviewCloseButton})}
        onClick={closeWixModal}>
        <CloseWithBackground />
      </button>
    );
  }
);
